import { PrintingMethod } from '@ticketmaster/tm1pos-tmds-client';
import { EMAIL, PRINT, SMS } from '@ticketmaster/tm1pos-web-shared/constants';
import { selectEmvPaymentModuleState } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-selectors';
import { selectActivePrinter } from '@ticketmaster/tm1pos-web-shared/printing/printingModule-selectors';
import { getInventoryEventId, getInventoryTypeByEventId } from '@ticketmaster/tm1pos-web-shared/store/events/utils';
import { selectCurrentEventDetails } from '@ticketmaster/tm1pos-web-shared/store/selectors';
import { select } from 'redux-saga-test-plan/matchers';
import type { Confirmation } from './utils';
import type { CheckoutPayment } from '@ticketmaster/tm1pos-web-shared/model/checkout-payment';
import type { OrderItem } from '@ticketmaster/tm1pos-web-shared/model/order-item';
import type { Printer } from '@ticketmaster/tm1pos-web-shared/printing';
import type { Name } from '@ticketmaster/tm1pos-web-shared/src/model/name';
import type { EventStore } from '@ticketmaster/tm1pos-web-shared/store/events/events.slice';

export const getRecipient = (deliveryMethod: string, userInformation: UserInformation) => {
  switch (deliveryMethod) {
    case SMS:
      return userInformation.phoneNumber;
    case EMAIL:
      return userInformation.email;
    case PRINT:
    default:
      return undefined;
  }
};

export function* buildDeliveryRequest(
  deliveryMethod: string,
  payments: CheckoutPayment[],
  items: OrderItem[],
  orderConfirmation: Confirmation,
  userInformation: UserInformation,
  receiptQuantity: number,
) {
  const currentEventDetails: EventStore = yield select(selectCurrentEventDetails);
  const printer: Printer = yield select(selectActivePrinter);
  const { transaction } = yield select(selectEmvPaymentModuleState);

  let printingMethod;
  let printingDpi;
  if (printer) {
    printingMethod = printer.method || PrintingMethod.PDF;
    printingDpi = printer.dpi;
  }
  const paymentReference = transaction.paymentReference ? transaction.paymentReference : undefined;
  const recipient = getRecipient(deliveryMethod, userInformation);

  return {
    identifier: {
      id: orderConfirmation.orderData.id,
      system: getInventoryTypeByEventId(currentEventDetails.id),
      subSystem: currentEventDetails.hostName || '',
    },
    accountId: orderConfirmation.orderData.accountId,
    eventId: getInventoryEventId(currentEventDetails.id),
    paymentReference,
    method: deliveryMethod.toUpperCase(),
    recipient,
    eventDate: currentEventDetails.localTime,
    venue: currentEventDetails.venue,
    payments,
    orderItems: items,
    totals: orderConfirmation.orderData.totals,
    print: {
      method: printingMethod,
      dpi: printingDpi,
      receiptQuantity,
    },
    customer: {
      name: userInformation.customerName,
    },
  };
}

export type UserInformation = {
  email: string;
  phoneNumber: string;
  customerName: Name;
};
