import { createAction, createSlice } from '@reduxjs/toolkit';
import { persistLocalForageUtils } from '../utils/storage/persistLocalForageUtils';
import { getMessageFromQZError } from './qztray-messages-mapper';
export const initialState = {
    activePrinter: null,
    printerServiceRefreshed: false,
    connectionError: null,
    isQzConnecting: false,
    loading: false,
    printers: [],
    printingModuleInitialized: false,
    printTestTicketState: 'IDLE',
    payloadSentToPrinter: false,
};
const printingModuleSlice = createSlice({
    name: 'printingModule',
    initialState,
    reducers: {
        setQzConnecting: (state, action) => ({
            ...state,
            isQzConnecting: action.payload,
        }),
        setLoading: (state, action) => ({
            ...state,
            loading: action.payload,
        }),
        setPrinters: (state, action) => ({
            ...state,
            printers: action.payload,
        }),
        resetPrinterError: (state) => ({
            ...state,
            printerError: undefined,
        }),
        printingFailed: (state, action) => ({
            ...state,
            printerError: action.payload,
        }),
        setPayloadSentToPrinter: (state, action) => ({
            ...state,
            payloadSentToPrinter: action.payload,
        }),
        resetPayloadSentToPrinter: (state) => ({
            ...state,
            printerError: undefined,
            payloadSentToPrinter: false,
        }),
        setActivePrinter: (state, action) => {
            const printer = action.payload;
            persistLocalForageUtils.setItem('activePrinter', printer);
            return {
                ...state,
                activePrinter: printer,
            };
        },
        errorQzConnect: (state, { payload }) => ({
            ...state,
            connectionError: getMessageFromQZError(payload),
        }),
        clearErrorQzConnect: (state) => ({
            ...state,
            connectionError: null,
        }),
        setPrinterServiceRefreshed: (state, { payload }) => ({
            ...state,
            printerServiceRefreshed: payload,
        }),
        setPrintingModuleInitialized: (state, { payload }) => ({
            ...state,
            printingModuleInitialized: payload,
        }),
        setPrintTestTicketState: (state, { payload }) => ({
            ...state,
            printTestTicketState: payload,
        }),
        resetPrintTestTicket: (state) => ({
            ...state,
            printerError: undefined,
            printTestTicketState: 'IDLE',
        }),
    },
});
const PRINTING_MODULE_ACTION_PREFIX = printingModuleSlice.name;
export const initializePrintingModule = createAction(`${PRINTING_MODULE_ACTION_PREFIX}/initialize`);
export const errorQzPrinters = createAction(`${PRINTING_MODULE_ACTION_PREFIX}/errorQzPrinters`);
export const refreshPrinters = createAction(`${PRINTING_MODULE_ACTION_PREFIX}/refreshPrinters`);
export const reloadActivePrinter = createAction(`${PRINTING_MODULE_ACTION_PREFIX}/reloadActivePrinter`);
export const printTestTicket = createAction(`${PRINTING_MODULE_ACTION_PREFIX}/printTestTicket`);
export const printTickets = createAction(`${PRINTING_MODULE_ACTION_PREFIX}/print`);
export const { setQzConnecting, setPrinters, resetPrinterError, printingFailed, setActivePrinter, setLoading, errorQzConnect, clearErrorQzConnect, setPrinterServiceRefreshed, setPrintingModuleInitialized, setPrintTestTicketState, resetPrintTestTicket, setPayloadSentToPrinter, resetPayloadSentToPrinter, } = printingModuleSlice.actions;
export const printingModuleReducer = printingModuleSlice.reducer;
