import { LogComponent, LogStatus } from '@ticketmaster/tm1pos-web-shared/errorHandling/constants';
import {
  selectEMVMode,
  selectEmvPaymentModuleState,
  selectHasEmvTransaction,
} from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-selectors';
import {
  acknowledgeEmvTransaction,
  reverseEmvTransaction,
} from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-slice';
import { selectActivePrinter } from '@ticketmaster/tm1pos-web-shared/printing/printingModule-selectors';
import { toLogError } from '@ticketmaster/tm1pos-web-shared/services/errors/error-handler';
import { hasErrors as hasGraphQLErrors } from '@ticketmaster/tm1pos-web-shared/services/sales-api-client/sales-api-functions';
import { logClientToCloud } from '@ticketmaster/tm1pos-web-shared/store/actions';
import { selectHostPermissions } from '@ticketmaster/tm1pos-web-shared/store/selectors/permissions-selector';
import { selectUserCountryCode } from '@ticketmaster/tm1pos-web-shared/store/selectors/user-selector';
import { checkDefined } from '@ticketmaster/tm1pos-web-shared/utils';
import { getUserIdentityData } from '@ticketmaster/tm1pos-web-shared/utils/sagas-utils';
import { all, call, put, select } from 'redux-saga/effects';
import { checkout } from '../../../../services/sales-api-client/sales-api-shopping';
import { CLEAR_CART_SUCCESS } from '../../../App/actions-constants';

import {
  selectCart,
  selectCartId,
  selectCartItems,
  selectCartItemsLength,
  selectCartTotals,
  selectPaymentCertificate,
} from '../../../App/selectors';
import {
  selectCurrentEvent,
  selectCurrentEventDetails,
  selectOrderIdToRetry,
} from '../../../EventDetailPage/selectors/main';
import { selectIsEmvPayment } from '../../selectors';
import { checkoutMutationInProgress } from './actions';
import { FETCH_CHECKOUT } from './actions-constants';
import { cartItemsContainsQualifiedHolds, getApiPayment } from './checkout-mapper';
import { UNSUPPORTED_CARD_TYPE } from './constants';
import { createReceiptPaymentInfos } from './delivery/receipt-payment-info-factory';
import { fetchCheckoutError } from './errors/checkout-error-actions';
import { handleCheckoutError } from './errors/checkout-error-sagas';
import ThrowableCheckoutError, { checkoutError } from './errors/checkoutError';
import { deliver, startEMVTransaction } from './sagas';
import {
  selectAvailablePaymentMethodsForCheckout,
  selectCheckoutInProgress,
  selectFormDataForCheckout,
  selectLinkGlobalAccount,
  selectPayments,
  selectPrintReceiptCheckoutData,
  selectUserInfoForCheckout,
} from './selectors';
import { confirmationDataFactory, getPaymentProcess } from './utils';
import type { PaymentData } from './payment.data';
import type { CheckoutParams } from '../../../../services/sales-api-client/model/checkout-params';
import type { Payment } from '../../../../services/sales-api-client/model/payment';
import type { Pricing } from '@ticketmaster/tm1pos-web-shared/model/graphql';
import type { OrderItem } from '@ticketmaster/tm1pos-web-shared/model/order-item';
import type { EmvPaymentModuleState } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-slice';
import type { EmvTransaction } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-types';
import type { EMVService } from '@ticketmaster/tm1pos-web-shared/payment/EMVService';
import type { Printer } from '@ticketmaster/tm1pos-web-shared/printing';
import type { SagaReturnType } from '@ticketmaster/tm1pos-web-shared/typings/saga-types';

export function* startCheckout() {
  const isCheckoutInProgress: boolean = yield select(selectCheckoutInProgress);
  const hasEmvTransaction: boolean = yield select(selectHasEmvTransaction);

  if (isCheckoutInProgress && !hasEmvTransaction) {
    return;
  }

  yield put({ type: FETCH_CHECKOUT.LOADING });
  const isEmvPayment: boolean = yield select(selectIsEmvPayment);
  if (isEmvPayment) {
    yield call(startEMVTransaction);
  } else {
    yield call(handleCheckout);
  }
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function* handleCheckout() {
  let order: any;
  let customerInfo: any;
  let eventCode: any;
  let hostName: any;
  let purePhoneNums: any;
  let currentEvent: ReturnType<typeof selectCurrentEvent>;

  let items: OrderItem[];
  let receiptQuantity: number;
  let payments: SagaReturnType<typeof handlePayment>[];
  let isEmvPayment = false;
  let archticsUserId;
  let opCodes;
  let cartItems: any[];
  let cartId: string | null;
  let orderIdToRetry: ReturnType<typeof selectOrderIdToRetry>;
  let quantity: number;
  let countryCode: ReturnType<typeof selectUserCountryCode>;
  let paymentNode: any;
  let deliveryMethod: any;
  let country: any;
  let email: any;
  let phoneNumber: any;
  let fullName: any;
  let customerName: any;
  let selectedPayments: any[];
  let linkGlobalAccount: boolean;

  try {
    try {
      isEmvPayment = yield select(selectIsEmvPayment);
      ({ archticsUserId, opCodes } = yield call(getUserIdentityData));
      cartItems = yield select(selectCartItems);
      cartId = yield select(selectCartId);
      orderIdToRetry = yield select(selectOrderIdToRetry);
      quantity = yield select(selectCartItemsLength);
      countryCode = yield select(selectUserCountryCode);
      ({ paymentNode, deliveryMethod, country } = yield select(selectFormDataForCheckout));
      ({ email, phoneNumber, fullName, customerName } = yield select(selectUserInfoForCheckout));
      linkGlobalAccount = yield select(selectLinkGlobalAccount);

      selectedPayments = yield select(selectPayments);
      const emvPaymentModule: EmvPaymentModuleState = yield select(selectEmvPaymentModuleState);
      validatePayments(selectedPayments, emvPaymentModule.transaction);

      const currentEventDetails: ReturnType<typeof selectCurrentEventDetails> = yield select(selectCurrentEventDetails);
      eventCode = currentEventDetails?.eventCode;
      hostName = currentEventDetails?.hostName;

      const totals: Pricing = yield select(selectCartTotals);
      currentEvent = yield select(selectCurrentEvent);

      items = cartItems.map((item) => {
        const { row, section, name: seat, generalAdmission } = item;
        const ticketTypeId = item.type.current.id;
        const ticketTypeLabel = item.type.current.name;
        return { seat, row, section, ticketTypeId, ticketTypeLabel, generalAdmission } satisfies OrderItem;
      });
      const phoneNumberPrefix = country.name;
      purePhoneNums = phoneNumber ? `${phoneNumberPrefix}${phoneNumber}` : '';
      ({ count: receiptQuantity } = yield select(selectPrintReceiptCheckoutData));

      payments = yield all(selectedPayments.map((paymentData) => call(handlePayment, paymentData, fullName)));

      order = {
        totals,
      };

      customerInfo = {
        note: paymentNode,
        phone: purePhoneNums,
        name: customerName,
        email,
        address: {
          country: countryCode,
        },
      };
    } catch (error: any) {
      yield put(
        logClientToCloud({
          type: LogComponent.CHECKOUT,
          status: LogStatus.ERROR,
          data: { error: toLogError(error) },
        }),
      );

      if (isEmvPayment) {
        yield put(reverseEmvTransaction());
      }
      yield put(fetchCheckoutError(checkoutError(error.errorType)));
      return;
    }

    yield put(checkoutMutationInProgress(true));

    const response: SagaReturnType<typeof checkout> = yield call(checkout, {
      cartId,
      customerInfo,
      eventCode,
      hostName,
      order,
      orderIdToRetry,
      deliveryMethod: deliveryMethod.toUpperCase(),
      payments,
      linkGlobalAccount,
    } satisfies CheckoutParams);
    yield put(checkoutMutationInProgress(false));

    const hasErrors = hasGraphQLErrors(response);
    if (hasErrors) {
      if (isEmvPayment) {
        yield put(reverseEmvTransaction());
      }

      yield call(handleCheckoutError, response.errors[0], response.data.checkout?.order?.accountId);
      return;
    }

    if (response.data && checkDefined(response, ['data', 'checkout', 'order', 'payments'])) {
      const rejectedOrder = response.data.checkout.order.payments.filter(
        (payment: Payment) => payment.status === 'REJECTED',
      );

      if (rejectedOrder.length === 0) {
        const emvMode: EMVService = yield select(selectEMVMode);
        const hasEmvTransaction: boolean = yield select(selectHasEmvTransaction);
        const paymentProcess = getPaymentProcess(selectedPayments, hasEmvTransaction, emvMode);

        const cart: unknown = yield select(selectCart);

        const orderConfirmation = confirmationDataFactory({
          orderData: response.data,
          ticketsData: cart,
          payments: selectedPayments,
          phoneNumber: purePhoneNums,
          customerName: fullName,
          emailAddress: email,
        });

        const containsQualifiedHolds: SagaReturnType<typeof cartItemsContainsQualifiedHolds> = yield call(
          cartItemsContainsQualifiedHolds,
          cartItems,
        );
        const ifPhoneNumber = purePhoneNums ? 'Phone' : '';
        const ifEnterName = fullName ? 'Name' : '';
        const ifEmail = email ? 'Email' : '';
        let buyerData = [ifPhoneNumber, ifEnterName, ifEmail].filter((item) => item).join(', '); // eslint-disable-line
        buyerData = buyerData || 'No Data';

        const activePrinter: Printer | null = yield select(selectActivePrinter);
        yield put({
          type: FETCH_CHECKOUT.SUCCESS,
          data: orderConfirmation,
          mixpanelOptions: {
            buyerData,
            deliveryMethod,
            opCodes,
            archticsUserId,
            quantity,
            amount: cart,
            type: currentEvent.type,
            paymentProcess,
            containsQualifiedHolds,
            printerService: activePrinter?.printerService,
            printerType: activePrinter?.type,
          },
        });
        if (isEmvPayment) {
          yield put(acknowledgeEmvTransaction());
        }

        if (!hasErrors) {
          const receiptPaymentInfos: SagaReturnType<typeof createReceiptPaymentInfos> = yield call(
            createReceiptPaymentInfos,
            payments,
            orderConfirmation.orderData,
          );

          const userInformation = {
            email,
            phoneNumber: purePhoneNums,
            customerName,
          };

          yield call(
            deliver,
            deliveryMethod,
            orderConfirmation,
            undefined,
            undefined,
            undefined,
            receiptPaymentInfos,
            items,
            userInformation,
            receiptQuantity,
          );
        }

        yield put({ type: CLEAR_CART_SUCCESS });
      }
    }
    // // TODO: need to clean credit card data
    // let changeDue = 0;
    // const cashToTender = orderConfirmation.totals ? orderConfirmation.totals.total.amount : orderConfirmation.ticketsData.totals.total.amount;
    // changeDue = Math.max(0, new Big(cashTendered).minus(cashToTender));
    // const changeDueAction = setChangeDue(cashTendered, changeDue, cashToTender);
    // yield put(changeDueAction);
  } catch (error) {
    yield put(
      logClientToCloud({
        type: LogComponent.CHECKOUT,
        status: LogStatus.ERROR,
        data: { error: toLogError(error) },
      }),
    );

    yield put(checkoutMutationInProgress(false));
    yield put({ type: FETCH_CHECKOUT.ERROR, error });
  }
}

export function* handlePayment(paymentData: PaymentData, fullName: string | undefined = '') {
  try {
    const hostPermissions: ReturnType<typeof selectHostPermissions> = yield select(selectHostPermissions);
    const currentEventDetails: ReturnType<typeof selectCurrentEventDetails> = yield select(selectCurrentEventDetails);
    const hostName = currentEventDetails?.hostName;
    const currentEvent: ReturnType<typeof selectCurrentEvent> = yield select(selectCurrentEvent);
    const paymentCertificate: unknown = yield select(selectPaymentCertificate);
    const availablePaymentMethodsForCheckout: unknown = yield select(selectAvailablePaymentMethodsForCheckout);
    const emvPaymentModuleState: EmvPaymentModuleState = yield select(selectEmvPaymentModuleState);
    const { isEmvSupported, transaction } = emvPaymentModuleState;

    return getApiPayment(
      currentEvent,
      paymentData,
      hostPermissions,
      hostName,
      fullName,
      availablePaymentMethodsForCheckout,
      paymentCertificate,
      isEmvSupported,
      transaction,
    );
  } catch (error) {
    yield put(
      logClientToCloud({
        type: LogComponent.CHECKOUT,
        status: LogStatus.ERROR,
        data: { error: toLogError(error) },
      }),
    );
    return {} as any;
  }
}

export function validatePayments(payments: any[], transaction: EmvTransaction) {
  payments.forEach((payment) => {
    if (!payment.method && !transaction.paymentReference) {
      throw new ThrowableCheckoutError('invalid payment method type', UNSUPPORTED_CARD_TYPE);
    }
  });
}
