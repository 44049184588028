import { DEFAULT_INVENTORY_TYPE_SELECTED } from '@ticketmaster/tm1pos-web-shared/constants';
import { LogComponent, LogStatus } from '@ticketmaster/tm1pos-web-shared/errorHandling/constants';
import { initializeEmvPaymentModule } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-slice';
import { TracedOperations } from '@ticketmaster/tm1pos-web-shared/services/tracer/tracer.service';
import { logClientToCloud, setInventoryType } from '@ticketmaster/tm1pos-web-shared/store/actions';
import {
  selectCurrentEventDetails,
  selectCurrentHostEventPermissions,
} from '@ticketmaster/tm1pos-web-shared/store/selectors';
import { checkDefined } from '@ticketmaster/tm1pos-web-shared/utils';
import {
  delay,
  fetchExternalData,
  getExponentialBackoff,
  handleResponse,
} from '@ticketmaster/tm1pos-web-shared/utils/sagas-utils';
import { localForageUtils } from '@ticketmaster/tm1pos-web-shared/utils/storage/localForageUtils';
import { LOCATION_CHANGE } from 'connected-react-router';
import { buffers, eventChannel } from 'redux-saga';
import {
  actionChannel,
  all,
  call,
  flush,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { v1 as uuid } from 'uuid';
import { selectCurrentEventPaymentPermissions } from 'containers/Checkout/components/Form/checkout-selectors';
import {
  ARCHTICS_EVENTS_ADMIN_INVENTORY_STATUS_MAX_ATTEMPTS,
  ARCHTICS_EVENTS_ADMIN_INVENTORY_STATUS_MAX_DELAY,
  ARCHTICS_EVENTS_ADMIN_INVENTORY_STATUS_REFRESH_RATE,
  ISM_HOST_SNAPSHOT_URL,
} from '../../constants';
import { clearCartLocation } from '../../middleware/mixpanel-constants';
import { HOST_ISM_SNAPSHOT } from '../../services/ismrt-client/model/ismrt-queries';
import { getArchticsSeatStatusSnapshot, getEventDetails } from '../../services/sales-api-client/sales-api-availability';
import { reserveBestAvailable, reserveSeat } from '../../services/sales-api-client/sales-api-shopping';
import { generateIsmApiToken } from '../../services/sales-api-client/sales-api-utils';
import { clearCart as clearCartAction, setDefaultInventoryType } from '../App/actions';
import { CLEAR_CART, TOGGLE_CHECKOUT } from '../App/actions-constants';
import { selectCartId, selectShowCart } from '../App/selectors';
import {
  getEventDetail,
  getIsmApiToken,
  loadSeatData,
  updateActiveTicketType,
  updateArchticsSeatStatus,
} from './actions';
import {
  ADA_FILTER_LIST_CHECKED_CHANGE,
  ADA_FILTER_LIST_TOGGLE,
  ARCHTICS_EVENT_SEAT_STATUS_FETCH,
  ARCHTICS_EVENT_TYPE,
  AVAILABILITY_DATA_LOAD,
  BEST_AVAILABLE_SEATS,
  CACHE_EVENT_REQUEST_DATA,
  CART_IN_WRONG_STATE_ERROR,
  EVENT_DETAIL_LOADED,
  EVENT_ID_SET,
  FETCH_BEST_AVAILABLE_SEATS,
  FETCH_EVENT_DETAILS,
  HOST_EVENT_TYPE,
  INITIAL_ARCHTICS_EVENT_SEAT_STATUS_FETCH,
  NO_SEATS_AVAILABLE,
  NO_TICKETTYPES_AVAILABLE,
  PLACE_TO_RESERVE_FETCH,
  PLACE_TO_RESERVE_QUEUE_FETCH,
  PLACE_TO_RESERVE_SELECT,
  SEAT_DATA_LOAD_FETCH,
  UNSUPPORTED_EVENT,
  WAIT_FOR_ICE_FRAME_UPDATE_DONE,
} from './constants';
import { updatePlaceStatus } from './containers/SearchMap/lib/Ism';
import { mapIsmrtPlacesToSeatStatus } from './containers/SearchMap/mapper';
import {
  finishLoadingCurrentEventPage,
  ismrtSnapshotDataError,
  ismrtSnapshotDataLoading,
  ismrtSnapshotDataSuccess,
  reloadIsmrtSnapshot,
  setSeatStatuses,
  startLoadingCurrentEventPage,
  updateIcePlaces,
} from './event-actions';
import { checkInventoryTypesAvailabilityCounts } from './event-detail-sagas';
import {
  getErrorEffectsFromBestAvailableResponse,
  getErrorEffectsFromISMSelectionResponse,
} from './reserve-sagaMapper';
import { selectAdaInfo, selectAdaTypes, selectArchticsSellClasses } from './selectors/adaTypes';
import { selectFbaPriceLevelGroups } from './selectors/best-availailable-selector';
import {
  selectArchticsFailedAvailabilityCalls,
  selectCurrentEvent,
  selectEventLoadedHasDispatched,
  selectFormattedReservedItems,
  selectHostDefaultInventoryType,
  selectInventoryTypes,
  selectIsLoadingBestAvailable,
  selectIsLoadingCurrentEventPage,
  selectIsLoadingPlaceReservation,
  selectSelectedInventoryType,
} from './selectors/main';
import { selectCurrentTicketTypeListRates } from './selectors/priceRange';
import {
  selectArchticsSeatStatusState,
  selectAvailabilityStatusLoading,
  selectTotalAvailableSeats,
} from './selectors/seatStatus';
import { selectCompoundEventId, selectEventType } from './selectors/selectEventIds';
import { selectTicketQty } from './selectors/ticketQty';
import {
  selectCurrentTicketType,
  selectIsLoadingTicketTypes,
  selectTicketTypesWithPromos,
} from './selectors/ticketType';

export const prepareArchticsOptions = ({ guid, dsn }) => ({
  headers: {
    GUID: guid,
    DSN: dsn,
  },
});

export function* fetchArchticsSeatStatusSnapshot(action) {
  try {
    const { ARCHTICS_EVENT_SEAT_STATUS_FETCH: snapshotStatus } = action;
    const eventId = yield select(selectCompoundEventId);
    const sellClasses = yield select(selectArchticsSellClasses);
    const adaInfo = yield select(selectAdaInfo);
    const adaTypes = adaInfo?.showFilters ? yield select(selectAdaTypes) : null;

    let previousData = [];
    if (!action.isInitial) {
      previousData = yield select(selectArchticsSeatStatusState);
    }

    yield put({ type: snapshotStatus.LOADING });
    const response = yield call(getArchticsSeatStatusSnapshot, eventId, sellClasses, adaTypes);
    yield put({ type: snapshotStatus.SUCCESS, data: response, previousData });
    yield call(handleResponse, snapshotStatus, response, true);
  } catch (error) {
    console.error('archtics snapshot error', error); // eslint-disable-line no-console
  }
}

export function* getHostSeatData(action) {
  yield put({ type: action.LOADING });
  yield fork(fetchIsmSnapshotDataForHost);
  yield put({ type: action.SUCCESS });
}

export function* getTicketTypesPlaceData(currentEvent) {
  yield fork(fetchEventDetails, getEventDetail());
  yield put({ type: AVAILABILITY_DATA_LOAD });

  if (currentEvent.type === HOST_EVENT_TYPE) {
    yield fork(getHostSeatData, loadSeatData());
  } else {
    yield fork(fetchArchticsSeatStatusSnapshot, updateArchticsSeatStatus(currentEvent.id, true));
  }
}

export function* fetchISMApiToken() {
  const compoundEventId = yield select(selectCompoundEventId);
  const action = getIsmApiToken(compoundEventId);

  let token = yield localForageUtils.getItem(compoundEventId + CACHE_EVENT_REQUEST_DATA.ismApiToken);
  if (token) {
    yield put({ type: action.CACHE, data: token });
  } else {
    const response = yield call(generateIsmApiToken, compoundEventId);
    yield call(handleResponse, action, response);
    token = response.data.token;
  }

  return token;
}

export function* getEventDetailStart() {
  yield put(startLoadingCurrentEventPage());
  const currentEvent = yield select(selectCurrentEvent);
  yield call(getTicketTypesPlaceData, currentEvent);
}

// Wait for successful response or error, then fire another request
export function* startArchticsSeatStatusFlow(action) {
  const failedAvailabilityCalls = yield select(selectArchticsFailedAvailabilityCalls);
  let showCart = yield select(selectShowCart);
  if (!showCart) {
    const bypassDelay =
      action &&
      (action.type === ADA_FILTER_LIST_TOGGLE ||
        action.type === ADA_FILTER_LIST_CHECKED_CHANGE ||
        action.type === TOGGLE_CHECKOUT);
    let results = { refresh: bypassDelay };
    if (!bypassDelay) {
      results = yield race({
        refresh: delay(
          getExponentialBackoff(
            ARCHTICS_EVENTS_ADMIN_INVENTORY_STATUS_REFRESH_RATE,
            failedAvailabilityCalls,
            ARCHTICS_EVENTS_ADMIN_INVENTORY_STATUS_MAX_DELAY,
          ),
        ),
        locationChange: take(LOCATION_CHANGE),
        toggling: take(ADA_FILTER_LIST_TOGGLE),
        checkout: take(TOGGLE_CHECKOUT),
      });
    }
    const currentEvent = yield select(selectCurrentEvent);
    showCart = yield select(selectShowCart);
    if (
      !showCart &&
      currentEvent.id &&
      currentEvent.mapId &&
      currentEvent.type === ARCHTICS_EVENT_TYPE &&
      results.refresh &&
      failedAvailabilityCalls <= ARCHTICS_EVENTS_ADMIN_INVENTORY_STATUS_MAX_ATTEMPTS
    ) {
      yield race({
        refresh: call(fetchArchticsSeatStatusSnapshot, updateArchticsSeatStatus(currentEvent.id)),
        locationChange: take(LOCATION_CHANGE),
      });
    }
  }
}

export function* fetchEventDetails(action) {
  try {
    const eventId = yield select(selectCompoundEventId);
    const eventType = yield select(selectEventType);

    const { feeEquivalenceClassId = null } = yield select(selectCurrentHostEventPermissions);
    if (!feeEquivalenceClassId && eventType === HOST_EVENT_TYPE) {
      yield put(
        logClientToCloud({
          type: LogComponent.EVENT_DETAILS,
          status: LogStatus.INFO,
          data: {
            context: 'call get eventDetails without feeEquivalenceClassId',
          },
        }),
      );
    }

    const currentEventDetails = yield select(selectCurrentEventDetails);
    const hostName = currentEventDetails?.hostName;
    if (!hostName && eventType === HOST_EVENT_TYPE) {
      yield put(
        logClientToCloud({
          type: LogComponent.EVENT_DETAILS,
          status: LogStatus.INFO,
          data: {
            context: 'call get eventDetails without hostname',
          },
        }),
      );
    }

    const cachedEvent = yield localForageUtils.getItem(eventId + CACHE_EVENT_REQUEST_DATA.ticketType);
    if (cachedEvent) {
      yield put({ type: action.CACHE, data: cachedEvent });
    }

    yield put({ type: action.LOADING });
    const response = yield call(getEventDetails, eventId, feeEquivalenceClassId, hostName);

    yield put({ type: action.SUCCESS, data: response });
    yield call(handleResponse, action, response, true);
  } catch (error) {
    yield put({ type: action.ERROR });
    console.error('failed fetching event details', error); // eslint-disable-line no-console
  }
}

export function* onFetchEventDetailsSetTicketType(action) {
  const data = action?.data;
  const hasErrors = !!data?.errors?.length;

  if (!hasErrors) {
    yield call(setDefaultTicketType, data);
  } else if (data.errors.some((error) => error.code === UNSUPPORTED_EVENT)) {
    const unsupportedEventError = data.errors.find((error) => error.code === UNSUPPORTED_EVENT);
    yield put({ type: UNSUPPORTED_EVENT, errorType: unsupportedEventError.message });
  } else {
    yield put({ type: NO_TICKETTYPES_AVAILABLE });
  }
}

export function validateBestAvailableResponse(data) {
  const defaultDataResponse = { data: { data: { reserveBestAvailable: null } } };
  let isSuccessful;

  try {
    isSuccessful = (data.errors && data.errors.length) || !!data.data.reserveBestAvailable;
  } catch (error) {
    isSuccessful = false;
  }

  if (!isSuccessful) {
    return { ...data, ...defaultDataResponse };
  }
  return data;
}

export function* fetchBestAvailable(action) {
  let ticketQty = 0;
  try {
    const eventId = yield select(selectCompoundEventId);
    const ticketType = yield select(selectCurrentTicketType);
    const sellClass = yield select(selectSelectedInventoryType);
    const currentEventDetails = yield select(selectCurrentEventDetails);
    const hostName = currentEventDetails?.hostName;
    const eventCode = currentEventDetails?.eventCode;

    ticketQty = yield select(selectTicketQty);
    const priceLevelGroups = yield select(selectFbaPriceLevelGroups);
    const useCart = yield select(selectCartId);

    yield put({ type: action.LOADING });

    const data = validateBestAvailableResponse(
      yield call(reserveBestAvailable, {
        eventId,
        priceLevelGroups,
        quantity: ticketQty,
        ticketType: ticketType.id,
        sellClass,
        hostName,
        eventCode,
        useCart,
      }),
    );
    const effects = getErrorEffectsFromBestAvailableResponse(data, ticketQty);
    if (effects.length) {
      yield all(effects);
      return;
    }

    const modifiedTickets = yield select(selectFormattedReservedItems(data.data.reserveBestAvailable.items, true));
    const rowData = { ...data.data.reserveBestAvailable };
    const modifiedData = { ...data.data.reserveBestAvailable, items: modifiedTickets };
    yield put({
      type: action.SUCCESS,
      rowCart: rowData,
      data: modifiedData,
    });
  } catch (error) {
    yield put({
      type: action.ERROR,
      error,
    });
    console.log('failed to receive best available seats', error); // eslint-disable-line no-console
  }
}

export function* getCartId() {
  let useCart = yield select(selectCartId);
  const isLoadingPlaceReservation = yield select(selectIsLoadingPlaceReservation);
  const isLoadingBestAvailable = yield select(selectIsLoadingBestAvailable);
  if (!useCart && (isLoadingPlaceReservation || isLoadingBestAvailable)) {
    const firstCartCallResult = yield race({
      placeReserveSuccess: take(PLACE_TO_RESERVE_FETCH.SUCCESS),
      placeReserveError: take(PLACE_TO_RESERVE_FETCH.ERROR),
      bestAvailSuccess: take(FETCH_BEST_AVAILABLE_SEATS.SUCCESS),
      bestAvailError: take(FETCH_BEST_AVAILABLE_SEATS.ERROR),
    });
    useCart = yield select(selectCartId) || firstCartCallResult.placeReserveSuccess.data.data.reserveSinglePlace.id;
  }
  return useCart;
}

export function* fetchISMSelection(action) {
  try {
    const currentEventDetails = yield select(selectCurrentEventDetails);
    const hostName = currentEventDetails?.hostName;
    const eventCode = currentEventDetails?.eventCode;
    const eventId = yield select(selectCompoundEventId);
    const sellClass = yield select(selectSelectedInventoryType);
    const useCart = yield call(getCartId);
    const adaTypes = yield select(selectAdaTypes);
    const adaType = adaTypes.find((it) => it.id.includes(action.place.placeType.id));

    yield put({ type: action.LOADING });

    let data = yield call(reserveSeat, {
      eventId,
      place: action.place,
      hostName,
      eventCode,
      ticketType: action.activeTicketType.id,
      sellClass,
      useCart,
      adaRequired: adaType?.id[0] ? adaType.id[0] : null,
    });
    let errors = data.errors || [];
    if (!data.data || !data.data.reserveSinglePlace || errors.length > 0) {
      if (errors.length === 0) {
        errors = [{ message: 'Empty payload' }];
      }
      data = { errors };
    }
    yield all(getErrorEffectsFromISMSelectionResponse(data, action));

    if (checkDefined(data, ['data', 'reserveSinglePlace', 'items']) && data.data.reserveSinglePlace.items.length) {
      const modifiedTickets = yield select(selectFormattedReservedItems(data.data.reserveSinglePlace.items, false));
      const rowData = { ...data.data.reserveSinglePlace };
      const modifiedData = { ...data.data.reserveSinglePlace, ...{ items: modifiedTickets } };
      yield put({
        type: action.SUCCESS,
        rowCart: rowData,
        data: modifiedData,
      });
    }
  } catch (error) {
    yield put({
      type: action.ERROR,
      error,
      place: action.place,
    });
  }
}

export function* checkEventDetailIsLoaded() {
  const availabilityStatusLoading = yield select(selectAvailabilityStatusLoading);
  const isLoadingTicketType = yield select(selectIsLoadingTicketTypes);
  const eventLoadedHasDispatched = yield select(selectEventLoadedHasDispatched);
  if (!availabilityStatusLoading && !eventLoadedHasDispatched && !isLoadingTicketType) {
    yield put({ type: EVENT_DETAIL_LOADED });
  }
}

export function* checkTotalAvailableSeats() {
  const availabilityStatusLoading = yield select(selectAvailabilityStatusLoading);
  try {
    // checking that seat status data has loaded;
    if (availabilityStatusLoading) {
      return;
    }
    // everything has loaded now
    // delay b/c of random possible selector race condition
    yield delay(250);
    // having correct seat status and ism data we have full info about available seats;
    const total = yield select(selectTotalAvailableSeats);
    if (total === 0) {
      yield put({ type: NO_SEATS_AVAILABLE });
    }
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
  }
}

export function* watchUpdateArchticsSeatStatus() {
  yield takeLatest(
    [
      ARCHTICS_EVENT_SEAT_STATUS_FETCH.SUCCESS,
      ARCHTICS_EVENT_SEAT_STATUS_FETCH.ERROR,
      INITIAL_ARCHTICS_EVENT_SEAT_STATUS_FETCH.SUCCESS,
      INITIAL_ARCHTICS_EVENT_SEAT_STATUS_FETCH.ERROR,
      ADA_FILTER_LIST_TOGGLE,
      TOGGLE_CHECKOUT,
    ],
    startArchticsSeatStatusFlow,
  );
}

export function* watchEventIdSet() {
  yield takeLatest(EVENT_ID_SET, getEventDetailStart);
}

export function* watchFetchEventDetailsInventoryTypes() {
  yield takeLatest(FETCH_EVENT_DETAILS.SUCCESS, onFetchEventDetailsSetInventoryType);
}

export function* watchFetchEventDetailsTicketTypes() {
  yield takeEvery(FETCH_EVENT_DETAILS.SUCCESS, onFetchEventDetailsSetTicketType);
}

export function* watchBestAvailable() {
  yield takeEvery(BEST_AVAILABLE_SEATS, fetchBestAvailable);
}

export function* queueHostPlaceReserveRequests() {
  // queue these into actionChannel
  const chan = yield actionChannel(PLACE_TO_RESERVE_QUEUE_FETCH, buffers.expanding(10));
  try {
    while (chan) {
      const action = yield take(chan);
      const { cancelQueue, clearCart } = yield race({
        task: call(fetchISMSelection, action),
        cancelQueue: take(CART_IN_WRONG_STATE_ERROR),
        clearCart: take(CLEAR_CART),
      });
      if (clearCart !== undefined || cancelQueue !== undefined) {
        yield flush(chan);
      }
    }
  } catch (e) {
    yield flush(chan);
    console.log('queueHostPlaceReserveRequests', e); // eslint-disable-line no-console
  }
}

export function* eventTypeISMSelection(action) {
  const activeTicketType = yield select(selectCurrentTicketType);
  const currentEventDetails = yield select(selectCurrentEventDetails);
  const hostName = currentEventDetails?.hostName;
  const eventCode = currentEventDetails?.eventCode;
  yield put({ ...action, type: PLACE_TO_RESERVE_QUEUE_FETCH, activeTicketType, eventCode, hostName });
}

export function* setDefaultTicketType() {
  const { standardTypes, promoTypes } = yield select(selectTicketTypesWithPromos);

  if (standardTypes.length === 0 && promoTypes.length === 0) {
    yield put({ type: NO_TICKETTYPES_AVAILABLE });
    return;
  }

  if (standardTypes.length === 0) {
    yield put(updateActiveTicketType(promoTypes[0]));
    return;
  }

  yield put(updateActiveTicketType(standardTypes[0]));
}

export function* fetchIsmSnapshotDataForHost() {
  const currentEvent = yield select(selectCurrentEvent);

  if (currentEvent && currentEvent.isHost) {
    const request = yield call(buildIsmSnapshotRequest, currentEvent);

    yield put(ismrtSnapshotDataLoading());

    const response = yield call(fetchExternalData, request);

    if (checkDefined(response, ['data', 'places'])) {
      yield put(
        ismrtSnapshotDataSuccess({
          places: response.data.places,
        }),
      );
    } else {
      yield put(ismrtSnapshotDataError());
    }
  }
}

export function* watchReloadIsmrtSnapshot() {
  yield takeEvery(reloadIsmrtSnapshot.type, reloadIsmSnapshotDataForHost);
}

export function* reloadIsmSnapshotDataForHost() {
  const currentEvent = yield select(selectCurrentEvent);
  const request = yield call(buildIsmSnapshotRequest, currentEvent);

  const response = yield call(fetchExternalData, request);

  if (checkDefined(response, ['data', 'places'])) {
    const seatStatusPlaces = yield call(mapIsmrtPlacesToSeatStatus, response.data.places);
    yield put(setSeatStatuses({ places: seatStatusPlaces }));
  }
}

export function* buildIsmSnapshotRequest(currentEvent) {
  const correlationId = yield call(uuid);

  let token = currentEvent.seatAttributeToken;
  if (!token) {
    token = yield call(fetchISMApiToken);
  }
  // fetch snapshot v1 data
  return {
    url: ISM_HOST_SNAPSHOT_URL,
    parentTracedOperation: TracedOperations.EventLoading,
    options: {
      headers: {
        'Content-Type': 'application/json',
        'TMPS-Correlation-Id': correlationId,
      },
      method: 'POST',
      body: JSON.stringify({
        query: HOST_ISM_SNAPSHOT,
        variables: {
          eventId: currentEvent.id,
          token,
        },
      }),
    },
  };
}

export function* clearCartOnLocationChange() {
  const clearPlace = clearCartLocation.LOCATION_CHANGED;
  yield put(clearCartAction(clearPlace));
}

export function* watchISMSelection() {
  yield takeEvery(PLACE_TO_RESERVE_SELECT, eventTypeISMSelection);
}

export function* watchEventDetailLoaded() {
  yield takeLatest([EVENT_DETAIL_LOADED], checkTotalAvailableSeats);

  yield takeLatest([EVENT_DETAIL_LOADED], checkInventoryTypesAvailabilityCounts);
}

export function* onFetchEventDetailsSetInventoryType() {
  let defaultInventoryTypeName = null;
  let error = null;

  try {
    const sellTypesList = (yield select(selectInventoryTypes)) || [];
    const hostDefaultInventoryType = yield select(selectHostDefaultInventoryType);

    defaultInventoryTypeName =
      hostDefaultInventoryType ||
      sellTypesList.find((type) => type.name === DEFAULT_INVENTORY_TYPE_SELECTED) ||
      sellTypesList[0] ||
      null;
  } catch (e) {
    error = e;
  }

  if (!defaultInventoryTypeName) {
    yield put(
      logClientToCloud({
        type: LogComponent.EVENT_DETAILS,
        status: LogStatus.ERROR,
        data: { detail: 'Default inventory type was set to null.', error },
      }),
    );
  }

  yield put(setInventoryType(defaultInventoryTypeName));
  yield put(setDefaultInventoryType(defaultInventoryTypeName));
}

export function* watchPlaceDataUpdated() {
  yield takeEvery(
    [
      SEAT_DATA_LOAD_FETCH.SUCCESS,
      FETCH_EVENT_DETAILS.SUCCESS,
      INITIAL_ARCHTICS_EVENT_SEAT_STATUS_FETCH.SUCCESS,
      FETCH_EVENT_DETAILS.ERROR,
    ],
    checkEventDetailIsLoaded,
  );
}

export function* watchLocationChange() {
  yield takeEvery(LOCATION_CHANGE, clearCartOnLocationChange);
}

export function* doUpdateIcePlaces({ payload: { ice, data, intl } }) {
  yield call(updatePlaceStatus, { ice, data, intl });

  const isLoadingCurrentEventPage = yield select(selectIsLoadingCurrentEventPage);

  if (isLoadingCurrentEventPage) {
    yield call(waitForIceFrameUpdate, ice);
    const allPriceLevels = yield select(selectCurrentTicketTypeListRates);

    yield put(
      finishLoadingCurrentEventPage({
        seatAmount: data?.length || 0,
        priceLevelAmount: allPriceLevels.length,
      }),
    );
  }
}

export function* waitForIceFrameUpdate(ice) {
  const iceFrameUpdateChannel = eventChannel((emit) => {
    if (ice) {
      ice.once('frame-update', () => emit(WAIT_FOR_ICE_FRAME_UPDATE_DONE));
    } else {
      emit(WAIT_FOR_ICE_FRAME_UPDATE_DONE);
    }

    return () => {};
  });

  yield take(iceFrameUpdateChannel);
  iceFrameUpdateChannel.close();
}

export function* watchUpdateIcePlaces() {
  yield takeLatest(updateIcePlaces.type, doUpdateIcePlaces);
}

export function* initializeEmvModule() {
  const permissions = yield select(selectCurrentEventPaymentPermissions);
  yield put(initializeEmvPaymentModule(permissions));
}

export function* watchForInitializeEmvModule() {
  yield takeEvery(startLoadingCurrentEventPage.type, initializeEmvModule);
}

export default [
  queueHostPlaceReserveRequests,
  watchUpdateArchticsSeatStatus,
  watchEventIdSet,
  watchFetchEventDetailsInventoryTypes,
  watchEventDetailLoaded,
  watchBestAvailable,
  watchISMSelection,
  watchPlaceDataUpdated,
  watchFetchEventDetailsTicketTypes,
  watchLocationChange,
  watchUpdateIcePlaces,
  watchForInitializeEmvModule,
  watchReloadIsmrtSnapshot,
];
