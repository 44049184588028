/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */
import dynamicAction from '@ticketmaster/tm1pos-web-shared/components/utils/DynamicNameHelper';

export const FINISHED_LOCALE_RETRIEVAL = 'FINISHED_LOCALE_RETRIEVAL';

export const USER_INACTIVITY = 'USER_INACTIVITY';
export const USER_TIMEOUT = 'USER_TIMEOUT';
export const START_TIMEOUT = 'START_TIMEOUT';
export const START_LOGIN = 'START_LOGIN';
export const PAYMENT_CERTIFICATE_SUCCESS = 'PAYMENT_CERTIFICATE_SUCCESS';
export const PAYMENT_CERTIFICATE_FAILED = 'PAYMENT_CERTIFICATE_FAILED';
export const FINISHED_PAYMENT_CERTIFICATE_RETRIEVAL = 'FINISHED_PAYMENT_CERTIFICATE_RETRIEVAL';
export const TOGGLE_CHECKOUT = 'TOGGLE_CHECKOUT';
export const APP_LOADED = 'APP_LOADED';
export const CLEAR_CART = 'CLEAR_CART';

export const CLEAR_CART_SUCCESS = 'CLEAR_CART_SUCCESS';
export const CLEAR_CART_FAILED = 'CLEAR_CART_FAILED';
export const SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK';
export const GET_FEEDBACK_CONTACTS = 'GET_FEEDBACK_CONTACTS';
export const FETCH_FEEDBACK_CONTACTS = dynamicAction(GET_FEEDBACK_CONTACTS);
export const FETCH_SUBMIT_FEEDBACK = dynamicAction(SUBMIT_FEEDBACK);
export const CLOSE_FEEDBACK_MODAL = 'CLOSE_FEEDBACK_MODAL';
export const PROMPT_ORDER_DELETE = 'PROMPT_ORDER_DELETE';
export const START_ORDER_DELETE = 'APPROVE_ORDER_DELETE';
export const CLEAR_ORDER_DELETE = 'CLEAR_ORDER_DELETE';
export const REJECT_ORDER_DELETE = 'REJECT_ORDER_DELETE';
export const TMDS_STATUS_CHECK = 'TMDS_STATUS_CHECK';
export const QUALIFIED_HOLDS_TOGGLE = 'QUALIFIED_HOLDS_TOGGLE';
export const MULTIPLE_DATASOURCE_FROM_OPCODES_ERROR = 'MULTIPLE_DATASOURCE_FROM_OPCODES';

export const SET_DEFAULT_INVENTORY_TYPE = 'SET_DEFAULT_INVENTORY_TYPE';
export const CANCEL_EMV_CHECKOUT = 'CANCEL_EMV_CHECKOUT';
export const CLIENT_CLOUD_LOG = 'CLIENT_CLOUD_LOG';
