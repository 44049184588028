import { PRINT } from '@ticketmaster/tm1pos-web-shared/constants';
import { ARX_CC_TYPES_MAP, HOST_CC_TYPES_MAP } from '@ticketmaster/tm1pos-web-shared/creditCard-constants';
import { selectFeatureFlags } from '@ticketmaster/tm1pos-web-shared/feature-flags';
import {
  selectEmvPaymentModuleState,
  selectHasEmvTransaction,
} from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-selectors';
import { selectActivePrinter } from '@ticketmaster/tm1pos-web-shared/printing/printingModule-selectors';
import { selectCurrentEventDetails } from '@ticketmaster/tm1pos-web-shared/store/selectors';
import { checkDefined } from '@ticketmaster/tm1pos-web-shared/utils';
import creditCardType from 'credit-card-type';
import { formValueSelector, isInvalid } from 'redux-form/immutable';
import { createSelector } from 'reselect';
import {
  selectArchticsOtherPaymentMethods,
  selectArchticsPaymentMethods,
  selectCartItemsPrice,
  selectHostOtherPaymentMethods,
  selectHostPaymentMethods,
} from '../../../App/selectors';
import { ARCHTICS_EVENT_TYPE, HOST_EVENT_TYPE } from '../../../EventDetailPage/constants';
import { selectCurrentEvent } from '../../../EventDetailPage/selectors/main';
import { selectEventType } from '../../../EventDetailPage/selectors/selectEventIds';
import { CARD, CASH, DEFAULT_PRINT_RECEIPT_VALUE, OTHER, PIX, UNSUPPORTED_CARD_TYPE } from './constants';
import ThrowableCheckoutError from './errors/checkoutError';
import { findFirstAndLastNames, transformCardType } from './utils';

const formSelector = formValueSelector('checkout');

export const selectFormToggles = (state) => formSelector(state, 'paymentMethod', 'deliveryMethod', 'manualCardEntry');
export const selectFormPrintReceipts = (state) => formSelector(state, 'printReceipts');
export const selectFormPhoneNumber = (state) => formSelector(state, 'phoneNumber');
export const selectCreditCardAuthCode = (state) => formSelector(state, 'authCode');
export const selectPaymentNote = (state) => formSelector(state, 'paymentNode');
export const selectCCType = (state) => formSelector(state, 'creditCardTypes');
export const selectPaymentInfo = (state) => formSelector(state, 'paymentInfo');
export const selectCurrentOtherPaymentMethod = (state) => formSelector(state, 'otherPaymentMethod');
export const selectUserInfoState = (state) => formSelector(state, 'phoneNumber', 'email', 'fullName');
export const selectCreditCardFormData = (state) => formSelector(state, 'accountNumber', 'cardHolderName', 'expDate');
export const selectPrinterInfo = (state) => formSelector(state, 'printer');
export const selectInvalid = (state) => isInvalid('checkout')(state);
export const selectCheckoutInProgress = (state) => formSelector(state, 'checkoutInProgress');
export const selectChangeDue = (state) => formSelector(state, 'changeDue');
export const selectPhonePre = (state) =>
  formSelector(state, 'phonePre') || { id: 'US', name: '+1', value: 'US', label: '+1' };
export const selectCashTendered = (state) => formSelector(state, 'cashTendered');
export const selectCheckoutMutationInProgress = (state) => formSelector(state, 'checkoutMutationInProgress');
export const selectCheckoutError = (state) => formSelector(state, 'error');
export const selectLinkGlobalAccount = (state) => formSelector(state, 'linkGlobalAccount');

export const selectHasPrintReceipt = createSelector(
  selectFormToggles,
  selectFormPrintReceipts,
  ({ deliveryMethod, paymentMethod }, printReceipts) =>
    !!printReceipts?.get(paymentMethod).get(deliveryMethod)?.get('active'),
);

export const extractExpirationInfo = (expDate) => {
  if (expDate?.match(/^\d\d?\/\d\d(\d\d)?$/)) {
    let [month, yearTwoOrFourDigits] = expDate.split('/');
    month = parseInt(month, 10);
    yearTwoOrFourDigits = parseInt(yearTwoOrFourDigits, 10);

    const year = computeFullYear(yearTwoOrFourDigits);
    return {
      month,
      year,
    };
  }
  const today = new Date();

  const month = today.getMonth() + 1;
  const year = today.getFullYear() + 10;

  return {
    month,
    year,
  };
};

export const selectUseEmailAsDelivery = createSelector(selectEventType, (eventType) => eventType === HOST_EVENT_TYPE);

export const computeFullYear = (expireYear) => {
  if (expireYear >= 0 && expireYear < 100) {
    const currentYear = new Date().getFullYear();
    let century = Math.floor(currentYear / 100) * 100;
    // In the last 10 years of the century, we consider years 0-10 to be in next century
    if (expireYear <= 10 && currentYear - century >= 90) {
      century += 100;
    }
    return century + expireYear;
  }
  return expireYear;
};

export const selectPrintReceiptData = createSelector(
  selectFormToggles,
  selectFormPrintReceipts,

  ({ deliveryMethod, paymentMethod }, printReceiptMap) => {
    const printReceiptOptions = printReceiptMap.get(paymentMethod).get(deliveryMethod);
    if (printReceiptOptions) {
      return printReceiptOptions.toJS();
    }
    return DEFAULT_PRINT_RECEIPT_VALUE;
  },
);

export const selectPrintReceiptCheckoutData = createSelector(selectPrintReceiptData, ({ active, quantity }) => {
  const count = parseInt(quantity.value, 10);
  const printReceipts = active && count > 0;
  const venueReceiptCopyRequired = active && count > 1;
  return {
    printReceipts,
    venueReceiptCopyRequired,
    count: active ? count : 0,
  };
});

export const selectExpDate = createSelector(selectCreditCardFormData, ({ expDate }) => extractExpirationInfo(expDate));

export const selectCreditCardInfo = createSelector(
  selectCreditCardFormData,
  selectExpDate,
  ({ accountNumber, cardHolderName }, expDate) => ({
    accountNumber,
    cardHolderName,
    ...expDate,
  }),
);

export const selectEmvCustomerName = createSelector(
  selectEmvPaymentModuleState,
  selectHasEmvTransaction,
  (emvPaymentModuleState, hasEmvTransaction) => {
    const { isEmvSupported, transaction } = emvPaymentModuleState;
    if (isEmvSupported && hasEmvTransaction) {
      const result = transaction.nameOnCard?.split('/');
      const lastName = result?.[0]?.trim();
      const firstName = result?.[1]?.trim();
      return !firstName || !lastName
        ? undefined
        : {
            firstName,
            lastName,
          };
    }

    return undefined;
  },
);

export const selectUserInfoForCheckout = createSelector(
  selectUserInfoState,
  selectCreditCardFormData,
  selectEmvCustomerName,
  (userInfo, { cardHolderName }, emvCustomerName) => {
    let { firstName: first, lastName: last } = findFirstAndLastNames(userInfo.fullName);
    if (!first && !last && cardHolderName) {
      const { firstName: cardHolderFirstName, lastName: cardHolderLastName } = findFirstAndLastNames(cardHolderName);
      first = cardHolderFirstName;
      last = cardHolderLastName;
    }

    if (!first && !last && emvCustomerName) {
      first = emvCustomerName.firstName;
      last = emvCustomerName.lastName;
    }

    return {
      ...userInfo,
      customerName: {
        first,
        last,
      },
    };
  },
);

export const selectOtherPaymentMethods = createSelector(
  selectArchticsOtherPaymentMethods,
  selectHostOtherPaymentMethods,
  selectCurrentEvent,
  (archticsOtherPaymentMethods, hostOtherPaymentMethods, currentEvent) => {
    if (currentEvent.type === ARCHTICS_EVENT_TYPE) {
      return archticsOtherPaymentMethods;
    }
    if (currentEvent.type === HOST_EVENT_TYPE) {
      return hostOtherPaymentMethods;
    }
    return [];
  },
);

export const selectAvailablePaymentMethodsForCheckout = createSelector(
  selectArchticsPaymentMethods,
  selectHostPaymentMethods,
  selectCurrentEvent,
  selectFeatureFlags,
  (archticsPaymentMethods, hostPaymentMethods, currentEvent, featureFlags) => {
    let allMethods = [];
    let methodsConvertMap = {};
    if (currentEvent.type === ARCHTICS_EVENT_TYPE) {
      allMethods = archticsPaymentMethods;
      methodsConvertMap = { ...ARX_CC_TYPES_MAP };
      // delete this block when remove 'interac' flag (and the test associated)
      if (!featureFlags.interacArchtics) {
        delete methodsConvertMap.DEBIT;
      }
    }

    if (currentEvent.type === HOST_EVENT_TYPE) {
      allMethods = hostPaymentMethods;
      methodsConvertMap = { ...HOST_CC_TYPES_MAP };
    }

    return {
      allMethods,
      methodsConvertMap,
    };
  },
);

export const selectFormDataForCheckout = createSelector(
  [
    selectFormToggles,
    selectCreditCardInfo,
    selectCurrentOtherPaymentMethod,
    selectPaymentInfo,
    selectUserInfoState,
    selectPrinterInfo,
    selectCreditCardAuthCode,
    selectPaymentNote,
    selectCCType,
    selectPhonePre,
  ],
  (
    formToggles,
    creditCardData,
    otherPaymentMethod,
    paymentInfo,
    userInfo,
    printer,
    authCode,
    paymentNode,
    creditCardTypes,
    country,
  ) => ({
    ...formToggles,
    ...creditCardData,
    otherPaymentMethod,
    paymentInfo,
    ...userInfo,
    printer,
    authCode,
    paymentNode,
    creditCardTypes,
    country,
  }),
);

function parseCardData(ccType, lastFourDigits) {
  return `${ccType.charAt(0).toUpperCase() + ccType.slice(1).toLowerCase()} ****${lastFourDigits}`;
}

export const generatePaymentMethodObject = (
  { paymentMethod, accountNumber, creditCardTypes, otherPaymentMethod },
  { allMethods, methodsConvertMap },
  paymentDetails,
) => {
  let method = null;
  let cardData = '';
  if (paymentMethod === CARD) {
    try {
      let ccType;
      if (accountNumber) {
        ccType = transformCardType(creditCardType(accountNumber)[0].type);
        cardData = parseCardData(ccType, accountNumber.substr(-4));
      } else if (paymentDetails?.cardType && paymentDetails?.lastFourDigits) {
        ccType = transformCardType(paymentDetails.cardType);
        cardData = parseCardData(ccType, paymentDetails.lastFourDigits);
      } else if (creditCardTypes) {
        ccType = transformCardType(creditCardTypes.id);
        cardData = ccType;
      }
      const mappedType = methodsConvertMap[ccType];
      method = allMethods.find((payment) => payment.type.toLowerCase() === mappedType?.toLowerCase());
    } catch (e) {
      throw new ThrowableCheckoutError('invalid card number', UNSUPPORTED_CARD_TYPE);
    }
  }
  if (paymentMethod === CASH) {
    method = allMethods.find(({ description }) => description.toLowerCase() === paymentMethod.toLowerCase());
  }
  if (paymentMethod === OTHER) {
    method = allMethods.find((payment) => payment.type.toLowerCase() === otherPaymentMethod.value.toLowerCase());
  }

  if (paymentMethod === PIX) {
    method = allMethods.find((payment) => payment.type.toLowerCase() === paymentMethod.toLowerCase());
  }

  return { method, cardData };
};
export const selectSolePaymentInfo = createSelector(
  [selectFormDataForCheckout, selectCartItemsPrice],
  (
    {
      paymentMethod,
      paymentInfo,
      month,
      year,
      creditCardTypes,
      otherPaymentMethod,
      accountNumber,
      authCode,
      cardHolderName,
    },
    cartAmount,
  ) => ({
    type: paymentMethod,
    paymentMethod,
    paymentInfo,
    expiration: {
      month,
      year,
    },
    accountNumber,
    creditCardTypes,
    otherPaymentMethod,
    authNumber: authCode,
    holderName: cardHolderName,
    amount: cartAmount.total,
  }),
);

export const selectPayments = createSelector(
  [
    selectEmvPaymentModuleState,
    selectFormDataForCheckout,
    selectAvailablePaymentMethodsForCheckout,
    selectSolePaymentInfo,
  ],
  (emvPaymentModuleState, formData, paymentMethodsData, solePayment) => {
    const { cardData, method } = generatePaymentMethodObject(
      formData,
      paymentMethodsData,
      emvPaymentModuleState.transaction.paymentDetails,
    );
    return [
      {
        ...solePayment,
        cardData,
        method,
        manualCardEntry: formData.manualCardEntry,
      },
    ];
  },
);

export const selectTerminalId = createSelector([selectCurrentEventDetails], (eventDetails) =>
  checkDefined(eventDetails, ['terminalId']) ? eventDetails.terminalId : null,
);

export const selectIsCheckoutEnabled = createSelector(
  [selectInvalid, selectActivePrinter, selectPrintReceiptCheckoutData, selectFormToggles],
  (invalid, activePrinter, { count: receiptQuantity }, { deliveryMethod }) => {
    if (deliveryMethod === PRINT || receiptQuantity > 0) {
      return !invalid && !!activePrinter;
    }
    return !invalid;
  },
);
