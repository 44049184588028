import dynamicAction from '@ticketmaster/tm1pos-web-shared/components/utils/DynamicNameHelper';

export const EVENT_ID_SET = 'EVENT_ID_SET';
export const CHECKOUT_EVENT_ID_SET = 'CHECKOUT_EVENT_ID_SET';
export const EVENT_DETAIL_LOADED = 'EVENT_DETAIL_LOADED';
export const ARCHTICS_EVENT_TYPE = 'ARCHTICS_EVENT_TYPE';
export const HOST_EVENT_TYPE = 'HOST_EVENT_TYPE';
export const NO_SEATS_AVAILABLE = 'NO_SEATS_AVAILABLE';
export const NO_TICKETTYPES_AVAILABLE = 'NO_TICKETTYPES_AVAILABLE';
export const TICKET_TYPE_NOT_AVAILABLE_ERROR = 'TICKET_TYPE_NOT_AVAILABLE_ERROR';
export const NO_BA_SEATS_FOUND = 'NO_SEATS_FOUND';
export const CART_IN_WRONG_STATE_ERROR = 'CART_IN_WRONG_STATE_ERROR'; // event to dispatch name
export const CART_SPLIT_ORDER_NOT_SUPPORTED_ERROR = 'CART_SPLIT_ORDER_NOT_SUPPORTED_ERROR';
export const CART_HAS_EXPIRED_INVENTORY_MASK = '0x200001';
export const INVENTORY_ERROR = 'INVENTORY_ERROR';

export const ARCHTICS_EVENT_SEAT_STATUS = 'ARCHTICS_EVENT_SEAT_STATUS';
export const ARCHTICS_EVENT_SEAT_STATUS_UPDATE = 'ARCHTICS_EVENT_SEAT_STATUS_UPDATE';
export const ARCHTICS_EVENT_SEAT_STATUS_INITIAL = 'ARCHTICS_EVENT_SEAT_STATUS_INITIAL';
export const ARCHTICS_EVENT_SEAT_SUMMARY = 'ARCHTICS_EVENT_SEAT_SUMMARY ';
export const ERROR_EVENT_DETAIL = 'ERROR_EVENT_DETAIL';
export const ISM_TOKEN = 'ISM_TOKEN';
export const ISM_LOAD = 'ISM_LOAD';
export const WAIT_FOR_ICE_FRAME_UPDATE_DONE = 'ICE_FRAME_UPDATE_DONE';
export const ISMRT_SNAPSHOT_RELOAD = 'ISMRT_SNAPSHOT_RELOAD';

export const SEAT_DATA_LOAD = 'SEAT_DATA_LOAD';
// fetch ticket types
export const UNSUPPORTED_EVENT = 'UNSUPPORTED_EVENT';
export const UNSUPPORTED_EVENT_ERRORS = {
  DUPLICATE_STATE: 'DUPLICATE_STATE',
  GRANULAR_PRICING: 'GRANULAR_PRICING',
  TRACKING_EVENT: 'TRACKING_EVENT',
};

export const ARCHTICS_EVENT_SEAT_STATUS_FETCH = dynamicAction(ARCHTICS_EVENT_SEAT_STATUS);
export const INITIAL_ARCHTICS_EVENT_SEAT_STATUS_FETCH = dynamicAction(ARCHTICS_EVENT_SEAT_STATUS_INITIAL);
export const ARCHTICS_SECTION_SEAT_SUMMARY_FETCH = dynamicAction(ARCHTICS_EVENT_SEAT_SUMMARY);
export const ISM_TOKEN_FETCH = dynamicAction(ISM_TOKEN);
export const ISM_LOAD_FETCH = dynamicAction(ISM_LOAD);
export const SEAT_DATA_LOAD_FETCH = dynamicAction(SEAT_DATA_LOAD);
export const AVAILABILITY_DATA_LOAD = 'AVAILABILITY_DATA_LOAD';
export const EVENT_AVAILABILITY = 'EVENT_AVAILABILITY';
export const EVENT_DETAILS = 'EVENT_DETAILS';
export const SEARCH_TICKET_TYPES_AND_OFFERS = 'SEARCH_TICKET_TYPES_AND_OFFERS';
export const ADA_FILTER_LIST_TOGGLE = 'ADA_FILTER_LIST_TOGGLE';
export const ADA_FILTER_LIST_CHECKED_CHANGE = 'ADA_FILTER_LIST_CHECKED_CHANGE';
export const BEST_AVAILABLE_SEATS = 'BEST_AVAILABLE_SEATS';
export const BEST_AVAILABLE_PRICE_MODAL_TOGGLE = 'BEST_AVAILABLE_PRICE_MODAL_TOGGLE';

export const FETCH_EVENT_AVAILABILITY = dynamicAction(EVENT_AVAILABILITY);
export const FETCH_EVENT_DETAILS = dynamicAction(EVENT_DETAILS);
export const FETCH_BEST_AVAILABLE_SEATS = dynamicAction(BEST_AVAILABLE_SEATS);
export const UPDATE_ACTIVE_PRICE_RANGE = 'UPDATE_ACTIVE_PRICE_RANGE';
export const UPDATE_ACTIVE_PRICE_LEVELS = 'UPDATE_ACTIVE_PRICE_LEVELS';
export const UPDATE_ACTIVE_TICKET_TYPE = 'UPDATE_ACTIVE_TICKET_TYPE';
export const CHECKOUT_PAYMENT_ERROR = 'CHECKOUT_PAYMENT_ERROR';

export const PLACE_TO_RESERVE_SELECT = 'PLACE_TO_RESERVE_SELECT';
export const PLACE_TO_RESERVE_QUEUE_FETCH = 'PLACE_TO_RESERVE_QUEUE_FETCH';
export const PLACE_TO_RESERVE_DESELECT = 'PLACE_TO_RESERVE_DESELECT';
export const PLACE_TO_RESERVE = 'PLACE_TO_RESERVE';
export const PLACE_TO_UNRESERVE = 'PLACE_TO_UNRESERVE';
export const PLACE_TO_RESERVE_FETCH = dynamicAction(PLACE_TO_RESERVE);
export const PLACE_TO_UNRESERVE_FETCH = dynamicAction(PLACE_TO_UNRESERVE);
export const PLACE_TOTALS_SET = 'PLACE_TOTALS_SET';

export const ADD_GA_WITH_SECTIONS = 'ADD_GA_WITH_SECTIONS';

export const UPDATE_TICKETS_QUANTITY = 'UPDATE_TICKETS_QUANTITY';

export const CACHE_EVENT_REQUEST_DATA = {
  ticketType: 'ticketType',
  ismAvailabilityToken: 'ismAvailabilityToken',
  ismApiToken: 'ismApiToken',
};
