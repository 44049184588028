import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { checkout, populateCreditCardData } from '../../actions';
import { CREDIT_CARD_SWIPE_ERROR } from '../../actions-constants';
import { CARD } from '../../constants';
import { selectFormToggles, selectIsCheckoutEnabled } from '../../selectors';
import { CREDIT_CARD_SWIPE_TRACK } from './constants';

export const inactiveIosCreditCardSwipeBridge = () => false;
window.iosCreditCardSwipeBridge = inactiveIosCreditCardSwipeBridge;

export class CreditCardSwipeInputBridge extends Component {
  static propTypes = {
    manualCardEntry: PropTypes.bool,
    isCheckoutEnabled: PropTypes.bool,
    paymentMethod: PropTypes.string,
    dispatch: PropTypes.func,
  };

  constructor() {
    super();
    this.state = {
      creditCardInput: '',
      error: '',
      justSwiped: false,
    };
  }

  componentDidMount() {
    window.document.addEventListener('keypress', this.onKeyPress, true);
    // bridge for mobile app
    window.iosCreditCardSwipeBridge = this.iosCreditCardSwipeBridge;
  }

  componentWillUnmount() {
    window.document.removeEventListener('keypress', this.onKeyPress, true);
    window.iosCreditCardSwipeBridge = inactiveIosCreditCardSwipeBridge;
  }

  /*
   */
  handleKeyInput(newInputPart) {
    if (CREDIT_CARD_SWIPE_TRACK.CC_REG_EX_START.test(newInputPart)) {
      return Object.assign(this.state, { creditCardInput: newInputPart });
    }
    return this.clearCreditCardInputState();
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  onKeyPress = (event) => {
    if (this.state.justSwiped) {
      this.clearCreditCardInputState();
      return false;
    }
    const { isCheckoutEnabled, manualCardEntry, paymentMethod } = this.props;
    if (!manualCardEntry && paymentMethod === CARD) {
      let newInputPart = this.state.creditCardInput + event.key;
      if (CREDIT_CARD_SWIPE_TRACK.CC_REG_EX_START.test(newInputPart)) {
        event.stopImmediatePropagation();
      }
      if (this.checkKeyForErrors(event.key, newInputPart)) {
        this.initiateError();
        return false;
      }
      if (event.key === '%') {
        newInputPart = '%';
      }

      if (this.checkForCompleteData(newInputPart) && isCheckoutEnabled) {
        this.completeSwipe(newInputPart);
        return false;
      }

      this.state = this.handleKeyInput(newInputPart);

      return false;
    }
    return true;
  };

  iosCreditCardSwipeBridge = (rawString, err) => {
    if (rawString) {
      this.state = this.handleKeyInput(rawString);
      this.onKeyPress({
        key: '',
        stopImmediatePropagation: () => {},
      });
    }
    if (err) {
      this.setState({ error: err });
    }
    return true;
  };

  resetJustSwiped = () => {
    this.setState(() => ({ justSwiped: false }));
  };

  checkForCompleteData(rawString) {
    return CREDIT_CARD_SWIPE_TRACK.CC_REG_EX_NEEDED_INPUT.test(rawString);
  }

  checkKeyForErrors(key, newInputPart) {
    return key === CREDIT_CARD_SWIPE_TRACK.ERROR_START && CREDIT_CARD_SWIPE_TRACK.CC_REG_EX_START.test(newInputPart);
  }

  clearCreditCardInputState() {
    this.state = Object.assign(this.state, { creditCardInput: '' });
    return this.state;
  }

  completeSwipe(rawString) {
    const data = this.parseCreditCardString(rawString);
    this.props.dispatch(populateCreditCardData(data));
    this.clearCreditCardInputState();
    this.props.dispatch(checkout());
    this.state.justSwiped = true;
    // Wait in case double swipes happen
    setTimeout(this.resetJustSwiped, 2000);
  }

  initiateError() {
    this.clearCreditCardInputState();
    this.setState({ error: 'Can not read card data' });
    this.props.dispatch({ type: CREDIT_CARD_SWIPE_ERROR, error: CREDIT_CARD_SWIPE_ERROR });
    this.render();
  }

  parseCreditCardString(rawString) {
    const [full, accountNumber, fullName, rawExp] = CREDIT_CARD_SWIPE_TRACK.CC_REG_EX_FORMAT.exec(rawString);
    const expDate = rawExp.replace(/(\d{2})(\d{2}).*/, '$2/$1');
    const [lastName, firstName] = fullName.trim().split('/');
    const cardHolderName = `${firstName} ${lastName}`;
    CREDIT_CARD_SWIPE_TRACK.CC_REG_EX_FORMAT.lastIndex = 0;
    return { accountNumber, expDate, full, cardHolderName };
  }

  render() {
    return this.state.error.length ? <div>{this.state.error}</div> : null;
  }
}

const mapStateToProps = createSelector(
  [selectFormToggles, selectIsCheckoutEnabled],
  (formToggles, isCheckoutEnabled) => ({
    ...formToggles,
    isCheckoutEnabled,
  }),
);

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardSwipeInputBridge);
