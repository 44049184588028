import { createSelector } from '@reduxjs/toolkit';
import { selectEmvPaymentModuleState } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-selectors';
import { TransactionStatus } from '@ticketmaster/tm1pos-web-shared/payment/emvPaymentModule-types';
import { selectCurrentHostEventPermissions } from '@ticketmaster/tm1pos-web-shared/store/selectors';
import { selectArchticsPermissions } from '@ticketmaster/tm1pos-web-shared/store/selectors/permissions-selector';
import { selectUserInformation } from '@ticketmaster/tm1pos-web-shared/store/selectors/user-selector';
import storage from '@ticketmaster/tm1pos-web-shared/utils/storage/storageService';
import { PAYMENT_CATEGORY } from './constants';

export const selectAdditionalUserDataLoaded = (state) => state.app.getIn(['additionalUserDataLoaded'], false);
export const selectUserLoadingState = (state) => state.oidc.isLoadingUser || state.app.get('isLoginStarted');
export const selectSuccessOrder = (state) => state.app.get('successOrder');
export const selectFeedbackContacts = (state) => state.app.get('feedbackContacts');
export const selectLoadingFeedbackSubmit = (state) => state.app.get('isLoadingFeedbackSubmit');
export const selectIsFeedbackSubmitted = (state) => state.app.get('isFeedbackSubmitted');
export const selectRemovingSeatPlacedId = (state) => state.app.get('removingSeatPlaceId');
export const selectCurrentEventId = (state) => state.app.get('currentEventId');
export const selectCurrentEventDetails = (state) =>
  (state.app ? state.app.get('currentEventDetails') : storage.getItem('currentEventDetails')) ||
  storage.getItem('currentEventDetails') ||
  {};

export const selectCertificateId = (state) => state.app.get('certificate_id');
export const selectPaymentCertificate = (state) => state.app.get('paymentCertificate');

export const selectArchticsPaymentMethods = createSelector(selectArchticsPermissions, (archticsPermissions) => {
  if (!archticsPermissions) {
    return [];
  }

  return Array.from(archticsPermissions.paymentMethods).sort((a, b) => a.description.localeCompare(b.description));
});
export const selectArchticsOtherPaymentMethods = createSelector(
  selectArchticsPaymentMethods,
  (archticsPaymentMethods) => {
    if (!archticsPaymentMethods) {
      return [];
    }
    const archticsOtherPaymentMethods = [];
    archticsPaymentMethods.forEach((method) => {
      if (
        method.category === PAYMENT_CATEGORY.OTHER ||
        method.category === PAYMENT_CATEGORY.CHECK ||
        method.category === PAYMENT_CATEGORY.CASH
      ) {
        archticsOtherPaymentMethods.push(method);
      }
    });
    return archticsOtherPaymentMethods;
  },
);

export const selectVenueDataFromPaymentDevice = createSelector(selectEmvPaymentModuleState, (emvPaymentModuleState) => {
  const transaction = emvPaymentModuleState.transaction;

  if (TransactionStatus.NONE === transaction.status) {
    return {};
  }

  const { address, city, state, country, phoneNumber: phone } = transaction.merchantDetails;
  return {
    address,
    city,
    state,
    country,
    phone,
  };
});

export const selectHostPaymentMethods = createSelector(
  selectCurrentHostEventPermissions,
  (currentPermissions) => currentPermissions.paymentMethods,
);

export const selectHostOtherPaymentMethods = createSelector(selectHostPaymentMethods, (paymentMethods) => {
  if (!paymentMethods) {
    return [];
  }
  return paymentMethods
    .filter((paymentMethod) => paymentMethod.category !== PAYMENT_CATEGORY.CREDIT_CARD && paymentMethod.description)
    .sort((a, b) => a.description.localeCompare(b.description));
});

export const selectFullName = createSelector(selectUserInformation, (user) => {
  const fullName = `${user.profile.firstName} ${user.profile.lastName}`;
  if (!fullName || !fullName.trim().length) {
    return null;
  }
  return fullName;
});

export const selectIdentityProvider = createSelector(
  selectUserInformation,
  (user) => user.profile.userIdentityProvider,
);

export const selectUsername = createSelector(selectUserInformation, (user) => user.profile.username);

export const selectCart = (state) => state.app.get('cart');
export const selectRowCart = (state) => storage.getItem('rowCart') || state.app.get('rowCart');
export const selectcheckoutCart = (state) => storage.getItem('checkoutCart') || state.app.get('checkoutCart');

export const selectSeatWithOrderItemId = createSelector(selectCart, (cart) => {
  const orders = {};
  const events = Object.entries(cart?.items || {});
  events.forEach(([eventId, eventOrder]) => {
    if (eventOrder) {
      orders[eventId] = eventOrder.orderId;
    }
  });
  return orders;
});

export const selectCartItemId = createSelector(
  selectSeatWithOrderItemId,
  selectRemovingSeatPlacedId,
  selectCurrentEventId,
  (seatWithOrderItemId, removingSeatPlacedId, eventId) => seatWithOrderItemId[eventId][removingSeatPlacedId],
);

export const selectEventSeatBlocks = (items) => {
  if (items) {
    return Object.entries(items).reduce((acc, [key, value]) => {
      const { sections } = value;
      acc[key] = Object.entries(sections).reduce((seatBlocks, [sectionKey, sectionValue]) => {
        const { rows } = sectionValue;
        Object.entries(rows).forEach(([rowKey, rowValue]) => {
          let generalAdmission = false;
          // set GA based on map or first seat
          if (rowValue.seats && rowValue.seats.length) {
            generalAdmission = rowValue.seats[0].GA || rowValue.seats[0].generalAdmission;
          }
          const seats = rowValue.seats.map((item) => ({ ...item, GA: generalAdmission, generalAdmission }));
          seatBlocks.push({
            section: sectionKey,
            row: rowKey,
            seats,
            generalAdmission,
          });
        });
        return seatBlocks;
      }, []);
      return acc;
    }, {});
  }
  return [];
};

export const selectCartBySeatBlocks = createSelector(selectCart, (cart) => {
  if (!cart) {
    return null;
  }
  const { items } = cart;
  const eventSeatBlocks = selectEventSeatBlocks(items);
  return { ...cart, eventSeatBlocks };
});

export const selectCartId = createSelector(selectCart, (cart) => {
  if (cart) {
    return cart.id;
  }
  return null;
});

export const selectShowCart = (state) => {
  const storageVal = storage.getItem('showCart');
  if (typeof storageVal === 'boolean') {
    return storageVal;
  }
  // if no initial storage value - use state and set in to storage
  const stateVal = state.app.get('showCart');
  storage.setItem('showCart', stateVal);
  return stateVal;
};

export const selectCartItemsLength = createSelector(selectCart, (cart) => {
  let ticketsLength = 0;
  try {
    Object.keys(cart.items).forEach((itemKey) => {
      ticketsLength += cart.items[itemKey].seatsCount;
    });
  } catch (err) {
    return ticketsLength;
  }
  return ticketsLength;
});

export const selectCartItemsPrice = createSelector(selectCart, (cart) => cart?.totals);

export const selectCartItems = createSelector(selectCart, (cart) => {
  try {
    const { items } = cart;
    const seats = [];
    Object.keys(items).forEach((itemKey) => {
      Object.keys(items[itemKey].sections).forEach((sectionKey) => {
        Object.keys(items[itemKey].sections[sectionKey].rows).forEach((rowKey) => {
          items[itemKey].sections[sectionKey].rows[rowKey].seats.forEach((seat) => {
            seats.push(seat);
          });
        });
      });
    });
    return seats.sort((a, b) => a.bagWeight - b.bagWeight);
  } catch (err) {
    return [];
  }
});

export const selectCartItemsIds = createSelector(selectCartItems, (items) => items.map((item) => item.placeId));

export const selectCartTotalTax = createSelector(selectCart, (cart) => {
  try {
    return cart.totals.tax.amount;
  } catch (error) {
    return 0;
  }
});

export const selectCartTotals = createSelector(selectCart, (cart) => {
  if (cart && cart.totals) {
    return cart.totals;
  }
  return null;
});

export const selectBestAvailableCartSeats = createSelector([selectCartItems], (cartItems) =>
  cartItems.filter((item) => item.isBestAvailable),
);

export const selectBestAvailableCartSeatsIds = createSelector([selectBestAvailableCartSeats], (baSeats) => {
  const idArr = [];
  baSeats.forEach((seat) => {
    if (seat.placeId) {
      idArr.push(seat.placeId);
    }
  });
  return idArr;
});
